import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  // {
  //   sousId: 1,
  //   tokenName: 'TWT',
  //   stakingTokenName: QuoteToken.SYRUP,
  //   stakingTokenAddress: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
  //   contractAddress: {
  //     97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //     56: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://trustwallet.com/',
  //   harvest: true,
  //   tokenPerBlock: '20',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
 // {
  //  sousId: 1,
   // tokenName: 'USDC',
   // stakingTokenName: QuoteToken.THEDON,
   // stakingTokenAddress: '0x62E96896d417dD929A4966f2538631AD5AF6Cb46',
   // contractAddress: {
   //   250: '0xB5AB9Ee614ac5a7cF34847037Ac5E52e91643Dee' // change to staking token address
   // },
   // poolCategory: PoolCategory.COMMUNITY,
   // projectLink: 'https://thedondao.gitbook.io/thedon-defi/dividends',
   // harvest: true,
   // tokenPerBlock: '0.00001',
   // sortOrder: 1,
   // isFinished: false, 
   // tokenDecimals: 18,
 // },
]

export default pools
