import { MenuEntry } from '@pancakeswap-libs/uikit'


const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Buy TheDon',
    icon: 'AuditIcon',
    href: 'https://spookyswap.finance/swap?outputCurrency=0x62E96896d417dD929A4966f2538631AD5AF6Cb46',
  },
  {
    label: 'Trade',
    icon: 'IfoIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://spookyswap.finance/swap?outputCurrency=0x62E96896d417dD929A4966f2538631AD5AF6Cb46',
      },
      {
        label: 'Liquidity',
        href: 'https://spookyswap.finance/add/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x62E96896d417dD929A4966f2538631AD5AF6Cb46',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'GroupsIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'New Layer - Life Finance',
    icon: 'NftIcon',
    href: 'https://lif3.exchange',
  },

   {
    label: 'Yield Wolf Vaults',
    icon: 'NftIcon',
    href: 'https://yieldwolf.finance/fantom/the-don',
  },
  
  {
    label: 'Charts',
    icon: 'InfoIcon',
    items: [
  
    {
    label: 'Dexscreener',
    href: 'https://dexscreener.com/fantom/0x7b4dcd189e215465f74e80449701fe027ec41827', // lp
  },
  {
    label: 'FarmScan',
    href: 'https://ftm.farmscan.io/address/0xa50ACA2e1c94652Ab842E64410bCe53247eF88ac', // masterchef
  },


  {
    label: 'KEK',
    href: 'https://kek.tools/t/0x62E96896d417dD929A4966f2538631AD5AF6Cb46',
  },
  ],
},
  
  
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  
  {
    label: 'Bridge',
    icon: 'TradeIcon',
    items: [
  
  {
    label: 'Multichain',
    href: 'https://multichain.org',
  },
  {
    label: 'EVODefi',
    href: 'https://bridge.evodefi.com/',
  
    },
   
]
},
{
    label: 'Documentation',
    icon: 'AuditIcon1',
    href: 'https://fatfiredao.gitbook.io/thedon/',
  },

]

export default config
