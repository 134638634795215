import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
 //  These 3 farms (PID 0, 251, 252) should always be at the top of the file.

   {
    pid: 0,
    risk: 5,

    lpSymbol: 'THEDON-WFTM LP', // token only in usdc? -wtfm lp - and pid 8 pool will be thhe same
      otherExchange: 'SpookySwap',

    lpAddresses: {
      97: '',
      250: '0x7B4DCD189e215465f74E80449701fe027EC41827',
    },
    tokenSymbol: 'THEDON',
    tokenAddresses: {
      97: '',
      250: '0x62E96896d417dD929A4966f2538631AD5AF6Cb46',
    },
    quoteTokenSymbol: QuoteToken.WMATIC, // cake is your token so this is only the token 
    quoteTokenAdresses: contracts.wmatic,
    tokenDecimals: 18,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'THEDON-USDC LP', // token-USDC LP
      otherExchange: 'SpookySwap',

  
    lpAddresses: {
      97: '',
      250: '0xe6c69c083b48710476e97A2eeca70Ce5B1511d1F',
    },
    tokenSymbol: 'THEDON',
    tokenAddresses: {
      97: '',
      250: '0x62E96896d417dD929A4966f2538631AD5AF6Cb46',
    },
    quoteTokenSymbol: QuoteToken.BUSD, // USDC
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },

  {
  pid: 2,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'THEDON',

  
    lpAddresses: {
      97: '',
      250: '0xe6c69c083b48710476e97a2eeca70ce5b1511d1f', 
    },
    tokenSymbol: 'THEDON',
    tokenAddresses: {
      97: '',
      250: '0x62E96896d417dD929A4966f2538631AD5AF6Cb46',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
    tokenDecimals: 18,
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'WFTM-USDC LP',
      otherExchange: 'SpookySwap',

  
    lpAddresses: {
      97: '',
      250: '0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c',
    },
    tokenSymbol: 'WFTM',
    tokenAddresses: {
      97: '',
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },

{
    pid: 4,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'BTC',
      otherExchange: 'SpookySwap',

  
    lpAddresses: {
      250: '0xd92206379bd8203ac38225af006bb96bf1f12412',
    },
    tokenSymbol: 'BTC',
    tokenAddresses: {
      250: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
   
 {
    pid: 5,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'ETH',  
      otherExchange: 'SpookySwap',


    lpAddresses: {
      250: '0xa572bdf049382f1f98f9a430788dadd51a303969',
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      250: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },

{
    pid: 6,
    risk: 2,
    isTokenOnly: true,
    lpSymbol: 'MIM',
      otherExchange: 'SpookySwap',

    lpAddresses: {
      250: '0x6f86e65b255c9111109d2D2325ca2dFc82456efc',
      97: '', // WFTM-MIM LP
    },
    tokenSymbol: 'MIM',
    tokenAddresses: {
      250: '0x82f0B8B456c1A451378467398982d4834b6829c1',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.MIM,
    quoteTokenAdresses: contracts.mim,
  },
  

   {
    pid: 7,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'USDC',
      otherExchange: 'SpookySwap',

  
    lpAddresses: {
      250: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      250: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 6,
  },


  {
    pid: 8,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'WFTM',
      otherExchange: 'SpookySwap',

  
    lpAddresses: {
      250: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c',
    },
    tokenSymbol: 'WFTM',
    tokenAddresses: {
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },

   {
    pid: 9,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'FATFIRE',
  otherExchange: 'SpookySwap',
    lpAddresses: {
      97: '',
      250: '0x7023f01e237505423e65c0cebc6493232659ff14',
    },
    tokenSymbol: 'fatfire',
    tokenAddresses: {
      97: '',
      250: '0xa5ee311609665Eaccdbef3BE07e1223D9dBe51de',
    },
    quoteTokenSymbol: QuoteToken.WMATIC,
    quoteTokenAdresses: contracts.wmatic,
    tokenDecimals: 18,
  },






]

export default farms
