import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
 //   font-family: 'Pixel Regular', monospace;
     font-family: 'Pixel Regular', 'Pixel Regular', sans-serif;

  }
  body {
  // background-color: #150423;
     background-color: #f7f2fa;

    // old @1f2128
	 // background-image: url('images/home/background.jpg');
  // uncomment to use a background image instead

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
