export default {
  cake: {
    250: '0x62E96896d417dD929A4966f2538631AD5AF6Cb46', // token change this
  },
  masterChef: {
    250: '0xa50ACA2e1c94652Ab842E64410bCe53247eF88ac', // change this
  },
  sousMasterChef: {
    250: '0x563373d51Eb8e9F9074216763a39F3aD79597edd',
  },
  weth: {
    250: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
  },
  lottery: {
    250: '',
  },
  lotteryNFT: {
    250: '',
  },
  mulltiCall: {
    250: '0xb828C456600857abd4ed6C32FAcc607bD0464F4F',
  },
  usdc: {
    250: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
  },
  busd: {
    250: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75', // usdc
  },
  dai: {
    250: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
  },
  usdt: {
    250: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9', // USDT
  },
  wbtc: {
    250: '0x321162Cd933E2Be498Cd2267a90534A804051b11', // wbtc
  },
  wmatic: {
    250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wftm
  },
  wbnb: {
    250: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1', // 
  },
  matic: {
    250: '0x0000000000000000000000000000000000001010',
  },
  mim: {
    250: '0x82f0B8B456c1A451378467398982d4834b6829c1',
  },
}
